import { EnvironmentInterface, generateApiUrlObject, developmentCommon } from './environment.common';

const name = 'test';
const baseApiUrl = `https://${name}.crm.justfood.pro/api/`;

export const environment: EnvironmentInterface = {
  ...developmentCommon,
  name,
  production: false,
  baseApiUrl,
  url: generateApiUrlObject(baseApiUrl),
  cloudPaymentsPublicId: 'pk_a96788d6e5d73075235b7e0348afc',
  oneSignal: {
    serverKey: 'AAAA60Th4k0:APA91bGuaGTvacpUJStFfcYR94Y96TeydEuK6UnVKIyitERnmfYVSyFAIO4mZcWhUazD4GZAP6XOgImgp365NnHAeXqJkUdefiS3G_YF6lOBwlBqae5vb4f-enkFmPFKUBVWBZu1DiJZ',
    senderID: '1010472968781',
    appID: 'cfcf6d43-6788-467c-9c57-70e417967b38'
  }
};
